@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);

:root {
  --primary: #7b00a3;
  --secondary: #999999;
  --ctf-white: #ffffff;
  --ctf-black: #000000;
  --ctf-third: #dddddd;
  --ctf-fourth: #dddddd;
  --ctf-fifth: #417505;
  --ctf-sixth: #f5f7f8;
  --ctf-seventh: #f6a51f;
  --ctf-eighth: #eeeeee;
  --ctf-ninth: #3a3b3f;
  --ctf-danger: #d0021b;
  /*font-size root*/
  --font-family: "Montserrat", sans-serif;
  --ctf-h1: 36px;
  --ctf-h2: 24px;
  --ctf-h3: 20px;
  --ctf-h4: 18px;
  --ctf-h5: 14px;
  --ctf-h6: 12px;
  --body-font-size: 12px;
  --es-radius: 5px;
}

/*scrollbar common css */
::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

::-webkit-scrollbar-track-piece {
  width: 13px;
}

::-webkit-scrollbar-thumb {
  background-color: #7b00a3;
  background-color: var(--primary);
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:vertical {
  background-color: #7b00a3;
  background-color: var(--primary);
  width: 13px;
  padding: 5px;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:horizontal {
  background-color: #7b00a3;
  background-color: var(--primary);
  border-radius: 15px;
  height: 10px;
}

/*do not touch css */
body {
  margin: 0px;
  padding: 0px;
  background: #f5f7f8;
  background: var(--ctf-sixth);
  color: #000000;
  color: var(--ctf-black);
  font-size: 12px;
  font-size: var(--body-font-size);
  line-height: 15px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-family: var(--font-family);
}

body::-webkit-scrollbar {
  width: 13px;
}

p {
  margin: 0px;
  padding: 0px;
  line-height: 15px;
  font-size: 12px;
  font-size: var(--body-font-size);
  font-weight: 400;
}

h1,
.h1 {
  font-size: 36px;
  font-size: var(--ctf-h1);
  line-height: 54px;
}

h2,
.h2 {
  font-size: 24px;
  font-size: var(--ctf-h2);
  line-height: 28px;
}

h3,
.h3 {
  font-size: 20px;
  font-size: var(--ctf-h3);
  line-height: 24px;
}

h4,
.h4 {
  font-size: 18px;
  font-size: var(--ctf-h4);
  line-height: 20px;
}

h5,
.h5 {
  font-size: 14px;
  font-size: var(--ctf-h5);
  line-height: 18px;
}

h6,
.h6 {
  font-size: 12px;
  font-size: var(--ctf-h6);
  line-height: 15px;
}

/*text color classes*/
.ctf-tx-primary {
  color: #7b00a3;
  color: var(--primary);
}

.ctf-tx-secondary {
  color: #999999;
  color: var(--secondary);
}

.ctf-tx-third {
  color: #dddddd;
  color: var(--ctf-third);
}

.ctf-tx-fourth {
  color: #dddddd;
  color: var(--ctf-fourth);
}

.ctf-tx-fifth {
  color: #417505;
  color: var(--ctf-fifth);
}

.ctf-tx-sixth {
  color: #f5f7f8;
  color: var(--ctf-sixth);
}

.ctf-tx-seventh {
  color: #f6a51f;
  color: var(--ctf-seventh);
}

/**/
/*background color classes*/
.ctf-bg-primary {
  background-color: #7b00a3;
  background-color: var(--primary);
}

.ctf-bg-secondary {
  background-color: #999999;
  background-color: var(--secondary);
}

.ctf-bg-third {
  background-color: #dddddd;
  background-color: var(--ctf-third);
}

.ctf-bg-fourth {
  background-color: #dddddd;
  background-color: var(--ctf-fourth);
}

.ctf-bg-fifth {
  background-color: #417505;
  background-color: var(--ctf-fifth);
}

.ctf-bg-sixth {
  background-color: #f5f7f8;
  background-color: var(--ctf-sixth);
}

.ctf-bg-seventh {
  background-color: #f6a51f;
  background-color: var(--ctf-seventh);
}

/*font-weight classes*/
.f-100 {
  font-weight: 100;
}

.f-300 {
  font-weight: 300;
}

.f-500 {
  font-weight: 500;
}

.f-700 {
  font-weight: 700;
}

.f-bold {
  font-weight: bold;
}

/*pointer scss*/
.c-pointer {
  cursor: pointer;
}

.border-bottom-1 {
  border-bottom: 1px solid #eeeeee;
  border-bottom: 1px solid var(--ctf-eighth);
}

/*common btn css*/
.btn {
  background: #7b00a3;
  background: var(--primary);
  border-radius: 5px;
  border-radius: var(--es-radius);
  font-size: 14px;
  font-size: var(--ctf-h5);
  color: #ffffff;
  color: var(--ctf-white);
  border: 1px solid #7b00a3;
  border: 1px solid var(--primary);
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-family: var(--font-family);
  text-transform: uppercase;
  padding: 12px;
  transition: 0.3s ease-in-out;
}

.btn:hover {
  background-color: transparent;
  color: #7b00a3;
  color: var(--primary);
  border-color: #7b00a3;
  border-color: var(--primary);
}

.btn-transparent {
  background-color: transparent;
  color: #7b00a3;
  color: var(--primary);
  border-color: #7b00a3;
  border-color: var(--primary);
}

.btn-transparent:hover {
  background: #7b00a3;
  background: var(--primary);
  border-radius: 5px;
  border-radius: var(--es-radius);
  font-size: 14px;
  font-size: var(--ctf-h5);
  color: #ffffff;
  color: var(--ctf-white);
  border: 1px solid #7b00a3;
  border: 1px solid var(--primary);
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-family: var(--font-family);
  text-transform: uppercase;
  padding: 12px;
  transition: 0.3s ease-in-out;
}

.btn-large {
  min-width: 380px;
}

.btn-medium {
  min-width: 200px;
}

.btn-small {
  min-width: 150px;
}

.btn-danger {
  background-color: transparent;
  color: #d0021b;
  color: var(--ctf-danger);
  border-color: #d0021b;
  border-color: var(--ctf-danger);
}

.btn-danger:hover {
  background-color: #d0021b;
  background-color: var(--ctf-danger);
  color: #ffffff;
  color: var(--ctf-white);
}

/*form input css*/
.custom-inputs {
  position: relative;
  margin-bottom: 40px;
}

.custom-inputs label {
  position: absolute;
  top: -8px;
  left: 20px;
  background: #ffffff;
  background: var(--ctf-white);
  padding: 0 15px;
}

.custom-inputs .form-control {
  font-size: 18px;
  font-size: var(--ctf-h4);
  padding: 16px;
  height: 56px;
  outline: transparent !important;
  box-shadow: none;
  color: #000000;
  color: var(--ctf-black);
  font-weight: 500;
}

.custom-inputs .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #999999;
  color: var(--secondary);
}

.custom-inputs .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #999999;
  color: var(--secondary);
}

.custom-inputs .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #999999;
  color: var(--secondary);
}

.custom-inputs .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #999999;
  color: var(--secondary);
}

.custom-inputs .form-control:focus {
  border-color: #7b00a3;
  border-color: var(--primary);
  outline: 0;
  box-shadow: none;
  border: 2px solid #7b00a3;
  border: 2px solid var(--primary);
}

.custom-inputs .form-control:focus + label {
  color: #7b00a3;
  color: var(--primary);
}

.custom-inputs textarea.form-control {
  height: 300px;
}

.custom-inputs .PhoneInput {
  position: relative;
}

.custom-inputs .PhoneInputCountry {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
}

.custom-inputs .PhoneInputInput {
  font-size: 18px;
  font-size: var(--ctf-h4);
  padding: 16px;
  height: 56px;
  padding-left: 40px;
  outline: transparent !important;
  box-shadow: none;
  color: #000000;
  color: var(--ctf-black);
  font-weight: 500;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-inputs .PhoneInputInput::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #999999;
  color: var(--secondary);
  vertical-align: middle;
}

.custom-inputs .PhoneInputInput::-moz-placeholder {
  /* Firefox 19+ */
  color: #999999;
  color: var(--secondary);
}

.custom-inputs .PhoneInputInput:-ms-input-placeholder {
  /* IE 10+ */
  color: #999999;
  color: var(--secondary);
}

.custom-inputs .PhoneInputInput:-moz-placeholder {
  /* Firefox 18- */
  color: #999999;
  color: var(--secondary);
}

.custom-inputs .PhoneInputInput:focus {
  border-color: #7b00a3;
  border-color: var(--primary);
  outline: 0;
  box-shadow: none;
  border: 2px solid #7b00a3;
  border: 2px solid var(--primary);
}

.custom-inputs .PhoneInputInput:focus + label {
  color: #7b00a3;
  color: var(--primary);
}

.custom-inputs.with-icon .form-control {
  padding-right: 50px;
}

.custom-inputs.with-icon .icons {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  right: 10px;
  cursor: pointer;
}

.custom-inputs.with-icon .icons svg {
  width: 25px;
  height: 25px;
}

.custom-inputs.with-icon .icons svg path {
  fill: #7b00a3;
  fill: var(--primary);
  transition: 0.3s ease-in-out;
}

.custom-inputs.with-icon .icons svg rect {
  fill: #7b00a3;
  fill: var(--primary);
}

.custom-inputs.with-icon .icons:focus svg path {
  fill: #f6a51f;
  fill: var(--ctf-seventh);
  transition: 0.3s ease-in-out;
}

.custom-inputs.with-icon .icons:focus svg rect {
  fill: #f6a51f;
  fill: var(--ctf-seventh);
}

.custom-inputs.with-icon .icons:hover svg path {
  fill: #f6a51f;
  fill: var(--ctf-seventh);
  transition: 0.3s ease-in-out;
}

.custom-inputs.with-icon .icons:hover svg rect {
  fill: #f6a51f;
  fill: var(--ctf-seventh);
}

.custom-inputs.error .form-control {
  border-color: #d0021b;
  border-color: var(--ctf-danger);
  border-color: #d0021b;
  border-color: var(--ctf-danger);
}

.custom-inputs.error .form-control + label {
  color: #d0021b;
  color: var(--ctf-danger);
}

.input-remain-content .input-title {
  padding: 16px;
  padding-left: 0px;
  word-break: break-all;
}

/* common input file type css */
[type="file"]::-webkit-file-upload-button {
  -webkit-appearance: button;
  cursor: pointer;
}

/*block padding*/
.blk-padding-x {
  padding: 0 24px;
}

.blk-padding-y {
  padding: 24px 0px;
}

.blk-padding-x-y {
  padding: 24px;
}

ul,
li {
  padding: 0px;
  margin: 0;
  list-style-type: none;
}

a {
  color: #7b00a3;
  color: var(--primary);
}

a:hover {
  color: #f6a51f;
  color: var(--ctf-seventh);
}

/*header css*/
.full-layout .navbar-block .navbar {
  position: relative;
}

.navbar-block .navbar {
  box-sizing: border-box;
  border: 1px solid #eeeeee;
  border: 1px solid var(--ctf-eighth);
  padding: 10px 40px;
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 0px;
  box-shadow: 0 4px 4px 0 rgba(221, 221, 221, 0.1);
}

.navbar-block .navbar .brand-logo {
  width: 50px;
}

.navbar-block .navbar button.navbar-toggler {
  outline: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-block .navbar button.navbar-toggler:focus, .navbar-block .navbar button.navbar-toggler:hover {
  background-color: #f6a51f;
  background-color: var(--ctf-seventh);
}

/*login pages*/
.login-format .full-layout-body {
  height: calc(100vh - 86px);
  display: flex;
  align-items: center;
}

.login-format .full-layout-body .full-layout-body-inner-block {
  background-color: #ffffff;
  background-color: var(--ctf-white);
  box-shadow: 0 4px 4px 0 rgba(221, 221, 221, 0.1);
  padding: 50px;
}

.login-format .full-layout-body .full-layout-body-inner-block .form-title.full-layout-title {
  text-align: center;
  padding-bottom: 50px;
}

.login-format .full-layout-body .full-layout-body-inner-block .forget-password-link {
  padding-bottom: 25px;
  text-align: right;
}

/*Contact us page css started*/
.contactus .contact-title {
  padding: 50px 0px;
  text-align: center;
}

.contactus .btn-block {
  padding: 50px 0px;
}

.contactus .btn-block .btn {
  margin: 0 15px;
}

.contect-body {
  margin: 25px 0 75px;
}

.contect-body .contact-input-main .wrapper-block {
  background-color: #ffffff;
  background-color: var(--ctf-white);
  box-shadow: 0 4px 4px 0 rgba(221, 221, 221, 0.1);
  padding: 50px;
}

.contect-body .contact-input-main .wrapper-block .form-title {
  padding-bottom: 35px;
  text-align: center;
  text-transform: uppercase;
}

.contect-body .contact-input-main .wrapper-block .contect-space {
  padding: 30px 0px;
  display: block;
  content: " ";
}

/*contact us options*/
.option .big-58 {
  font-size: 58px;
  font-weight: bold;
  line-height: 87px;
}

.option .custom-inputs textarea.form-control {
  height: 150px;
}

.option .title-block {
  padding-left: 150px;
}

.option .guess-blk {
  position: relative;
}

.option .guess-blk:before {
  position: absolute;
  top: 50%;
  width: 130px;
  left: -150px;
  height: 8px;
  background-color: #7b00a3;
  background-color: var(--primary);
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  display: block;
  content: " ";
  border-radius: 0 30px 30px 0;
}

/*sidebar css started*/
.sidebar {
  width: 300px;
  position: fixed;
  background-color: #ffffff;
  background-color: var(--ctf-white);
  height: calc(100vh - 86px);
  display: block;
  margin-top: 86px;
  top: 0px;
  z-index: 99;
  transition: 0.3s ease-in-out;
}

.sidebar .close-icon-sidebar {
  cursor: pointer;
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
}

.sidebar .close-icon-sidebar svg path {
  fill: #7b00a3;
  fill: var(--primary);
}

.sidebar .sidebar-wrapper {
  height: calc(100% - 54px);
  overflow-y: auto;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /*sidebar image block css*/
  /*side bar links list css started*/
}

.sidebar .sidebar-wrapper::-webkit-scrollbar {
  width: 0px;
}

.sidebar .sidebar-wrapper .add-dropdown-menu {
  width: 100%;
  left: 0%;
}

.sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item {
  display: flex;
  width: 100%;
  padding: 10px 15px;
  align-items: center;
}

.sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item .block-1 {
  margin-right: 10px;
  width: 35px;
  display: inline-flex;
  align-items: center;
}

.sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item .block-1 svg path {
  fill: #000000;
  fill: var(--ctf-black);
}

.sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item .block-1 svg g {
  opacity: 1;
}

.sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item .block-2 {
  width: calc(100% - 50px);
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-size: var(--ctf-h5);
}

.sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item:focus, .sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item.active, .sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item:active, .sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item:hover {
  background-color: transparent;
  outline: transparent;
  color: #7b00a3;
  color: var(--primary);
}

.sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item:focus .block-1 svg path,
.sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item:focus .block-1 svg circle, .sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item:active .block-1 svg path,
.sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item:active .block-1 svg circle, .sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item:active .block-1 svg path,
.sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item:active .block-1 svg circle, .sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item:hover .block-1 svg path,
.sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item:hover .block-1 svg circle {
  fill: #7b00a3;
  fill: var(--primary);
}

.sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item:focus .block-1 svg g, .sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item:active .block-1 svg g, .sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item:active .block-1 svg g, .sidebar .sidebar-wrapper .add-dropdown-menu .dropdown-item:hover .block-1 svg g {
  opacity: 1;
}

.sidebar .sidebar-wrapper .user-main-block {
  padding: 24px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
}

.sidebar .sidebar-wrapper .user-main-block .user-img-block {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
}

.sidebar .sidebar-wrapper .user-main-block .user-img-block img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.sidebar .sidebar-wrapper .user-main-block .username {
  padding-top: 18px;
  word-break: break-all;
}

.sidebar .sidebar-wrapper .seperator {
  display: block;
  width: calc(100% - 30px);
  border-bottom: 1px solid #999999;
  border-bottom: 1px solid var(--secondary);
  content: " ";
  margin: 0 auto;
}

.sidebar .sidebar-wrapper .sidebar-dropdown {
  padding: 25px;
  padding-left: 30px;
  padding-right: 30px;
}

.sidebar .sidebar-wrapper .sidebar-dropdown .custom-dropdown-button {
  font-size: 14px;
  font-size: var(--ctf-h5);
  background-color: #f6a51f;
  background-color: var(--ctf-seventh);
  color: #ffffff;
  color: var(--ctf-white);
  border-color: transparent;
  outline: transparent !important;
  box-shadow: none;
  text-align: left;
  padding-left: 18px;
}

.sidebar .sidebar-wrapper .sidebar-dropdown .custom-dropdown-button:after {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  right: 18px;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
}

.sidebar .sidebar-wrapper .list-ul {
  width: 100%;
  background-color: #ffffff;
  background-color: var(--ctf-white);
}

.sidebar .sidebar-wrapper .list-main-block {
  padding-left: 0px;
  margin-bottom: 0;
}

.sidebar .sidebar-wrapper .list-element {
  position: relative;
  display: block;
  background: transparent;
  width: 100%;
}

.sidebar .sidebar-wrapper .list-element .link-blk {
  text-decoration: none;
  color: #999999;
  color: var(--secondary);
  margin: 0 0 0 0;
  padding: 15px 0px;
  display: block;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  cursor: pointer;
}

.sidebar .sidebar-wrapper .list-element .link-blk .link-data {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}

.sidebar .sidebar-wrapper .list-element .link-blk .link-data .link-img {
  display: inline-flex;
  vertical-align: top;
  width: 35px;
  padding-left: 0;
  transition: all 0.2s;
}

.sidebar .sidebar-wrapper .list-element .link-blk .link-data .link-img svg g {
  opacity: 1;
}

.sidebar .sidebar-wrapper .list-element .link-blk .link-data .link-img svg circle {
  fill: #999999;
  fill: var(--secondary);
}

.sidebar .sidebar-wrapper .list-element .link-blk .link-data .link-img svg path {
  fill: #999999;
  fill: var(--secondary);
}

.sidebar .sidebar-wrapper .list-element .link-blk .link-data .link-text {
  display: inline-flex;
  width: calc(100% - 50px);
}

.sidebar .sidebar-wrapper .list-element:hover .link-blk, .sidebar .sidebar-wrapper .list-element:focus .link-blk {
  text-decoration: none;
  color: #7b00a3;
  color: var(--primary);
}

.sidebar .sidebar-wrapper .list-element:hover .link-blk:before, .sidebar .sidebar-wrapper .list-element:focus .link-blk:before {
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #7b00a3;
  background-color: var(--primary);
  display: block;
  content: " ";
  left: 0;
  top: 0;
}

.sidebar .sidebar-wrapper .list-element:hover .link-blk .link-data .link-img svg circle, .sidebar .sidebar-wrapper .list-element:focus .link-blk .link-data .link-img svg circle {
  fill: #7b00a3;
  fill: var(--primary);
}

.sidebar .sidebar-wrapper .list-element:hover .link-blk .link-data .link-img svg path, .sidebar .sidebar-wrapper .list-element:focus .link-blk .link-data .link-img svg path {
  fill: #7b00a3;
  fill: var(--primary);
}

.sidebar .sidebar-wrapper .list-element.active {
  background-color: #f5f7f8;
  background-color: var(--ctf-sixth);
}

.sidebar .sidebar-wrapper .list-element.active .curve {
  position: absolute;
  top: -25px;
  width: 30px;
  height: 30px;
  background: #f5f7f8;
  background: var(--ctf-sixth);
  right: 0;
}

.sidebar .sidebar-wrapper .list-element.active .curve-2 {
  position: absolute;
  bottom: -25px;
  width: 30px;
  height: 25px;
  background: #f5f7f8;
  background: var(--ctf-sixth);
  right: 0;
}

.sidebar .sidebar-wrapper .list-element.active .list-element-upper-curve {
  width: 30px;
  height: 25px;
  background: #ffffff;
  background: var(--ctf-white);
  position: relative;
  border-radius: 0 0 47px;
}

.sidebar .sidebar-wrapper .list-element.active .list-element-upper-curve-2 {
  width: 30px;
  height: 25px;
  background: #ffffff;
  background: var(--ctf-white);
  position: relative;
  border-radius: 0px 0px 0px 46px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.sidebar .sidebar-wrapper .list-element.active .link-blk {
  text-decoration: none;
  color: #7b00a3;
  color: var(--primary);
}

.sidebar .sidebar-wrapper .list-element.active .link-blk:before {
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #7b00a3;
  background-color: var(--primary);
  display: block;
  content: " ";
  left: 0;
  top: 0;
}

.sidebar .sidebar-wrapper .list-element.active .link-blk .link-data .link-img svg circle {
  fill: #7b00a3;
  fill: var(--primary);
}

.sidebar .sidebar-wrapper .list-element.active .link-blk .link-data .link-img svg path {
  fill: #7b00a3;
  fill: var(--primary);
}

.sidebar .sidebar-wrapper .list-element .dropdown {
  overflow: hidden;
}

.sidebar .sidebar-wrapper .list-element .dropdown .list-first-level-menu {
  position: relative !important;
  width: 100%;
  top: 0px !important;
  left: 0px !important;
  -webkit-transform: none !important;
          transform: none !important;
  background-color: transparent;
  border-color: transparent;
}

.sidebar .sidebar-wrapper .list-element .dropdown .list-first-level-menu .dropdown-item {
  display: block;
  width: 100%;
  padding: 15px 30px;
  clear: both;
  font-weight: 400;
  color: #999999;
  color: var(--secondary);
  outline: none !important;
  display: block;
  width: 100%;
  padding: 15px 0px 15px 65px;
  font-size: 14px;
  font-size: var(--ctf-h5);
  font-weight: 500;
}

.sidebar .sidebar-wrapper .list-element .dropdown .list-first-level-menu .dropdown-item a {
  color: #999999;
  color: var(--secondary);
  text-decoration: none;
}

.sidebar .sidebar-wrapper .list-element .dropdown .list-first-level-menu .dropdown-item:active {
  background-color: transparent;
}

.sidebar .sidebar-wrapper .list-element .dropdown .list-first-level-menu .dropdown-item.active {
  background-color: transparent;
  color: #7b00a3;
  color: var(--primary);
  position: relative;
}

.sidebar .sidebar-wrapper .list-element .dropdown .list-first-level-menu .dropdown-item.active a {
  color: #7b00a3;
  color: var(--primary);
  text-decoration: none;
}

.sidebar .sidebar-wrapper .list-element .dropdown .list-first-level-menu .dropdown-item.active:before {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%) rotate(-90deg);
          transform: translate(0, -50%) rotate(-90deg);
  left: 33px;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
  content: " ";
}

.sidebar .out-block {
  font-size: 14px;
  font-weight: bold;
  padding-left: 30px;
  padding-right: 30px;
}

.sidebar .out-block .link-main-block {
  padding: 15px 0px;
  display: block;
  text-decoration: none;
  color: #999999;
  color: var(--secondary);
}

.sidebar .out-block .link-main-block .link-img {
  display: inline-flex;
  vertical-align: top;
  width: 35px;
  padding-left: 0;
  transition: all 0.2s;
}

.sidebar .out-block .link-main-block .link-img svg path {
  fill: #999999;
  fill: var(--secondary);
}

.sidebar .out-block .link-main-block .content {
  display: inline-flex;
  width: calc(100% - 50px);
}

.sidebar .out-block .link-main-block:hover, .sidebar .out-block .link-main-block:focus {
  color: #7b00a3;
  color: var(--primary);
}

.sidebar .out-block .link-main-block:hover .link-img svg path, .sidebar .out-block .link-main-block:focus .link-img svg path {
  fill: #7b00a3;
  fill: var(--primary);
}

/*admin layout body*/
.admin-layout-body {
  margin-left: 300px;
  margin-top: 86px;
  width: calc(100% - 300px);
  background-color: #f5f7f8;
  background-color: var(--ctf-sixth);
  height: calc(100vh - 86px);
  display: block;
  top: 0;
}

.panel {
  padding: 25px 50px;
}

/*export-dropdown component css*/
.custom-dropdown .dropdown-toggle {
  outline: none;
  box-shadow: none;
  background-color: #7b00a3;
  background-color: var(--primary);
  border-color: #7b00a3;
  border-color: var(--primary);
  color: #ffffff;
  color: var(--ctf-white);
  transition: 0.3s ease-in-out;
  font-size: 14px;
  font-size: var(--ctf-h5);
  padding: 12px 18px;
}

.custom-dropdown .dropdown-toggle:hover {
  color: #7b00a3;
  color: var(--primary);
  border-color: #7b00a3;
  border-color: var(--primary);
  background-color: transparent;
}

.custom-dropdown.map-list-drp .dropdown-toggle {
  background-color: #ffffff;
  background-color: var(--ctf-white);
  border-color: #999999;
  border-color: var(--secondary);
  color: #999999;
  color: var(--secondary);
}

/**dashboard css started*/
.export-data-block {
  padding-bottom: 25px;
}

.information-block .data-block .block-2 {
  display: inline-block;
  width: 80px;
  height: 80px;
  vertical-align: text-top;
  background-color: #7b00a3;
  background-color: var(--primary);
  border-radius: 50%;
  padding: 15px;
}

.information-block .data-block .block-2 svg {
  width: 100%;
  height: 100%;
}

.information-block .data-block .block-2 svg g {
  opacity: 1;
}

.information-block .data-block .block-2 svg path,
.information-block .data-block .block-2 svg circle {
  fill: #ffffff;
  fill: var(--ctf-white);
}

.information-block .data-block .block-2.orange {
  background-color: #ec4c47;
}

.information-block .data-block .block-2.yellow {
  background-color: #f6a51f;
  background-color: var(--ctf-seventh);
}

.information-block .data-block .block-1 {
  display: inline-block;
  width: calc(100% - 80px);
  vertical-align: text-top;
}

.card-panel {
  background-color: #ffffff;
  background-color: var(--ctf-white);
  padding: 24px;
}

/*tittle css started*/
.info-title {
  padding-bottom: 20px;
}

.info-title.with-border {
  border-bottom: 1px solid #eeeeee;
  border-bottom: 1px solid var(--ctf-eighth);
}

/*info*/
.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.profile-image .img-blk {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.file-input-link .file-input {
  position: relative;
}

.file-input-link .file-input .upload-link {
  text-transform: uppercase;
}

.file-input-link .file-input input {
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  width: 140px;
  position: absolute;
  top: 0px;
}

.links {
  display: inline-block;
  width: auto;
  padding: 15px;
  cursor: pointer;
}

.links:hover {
  color: #7b00a3;
  color: var(--primary);
}

/*capsual scss*/
.capsual-list .capsual {
  display: inline-block;
  padding: 10px 15px;
  background: #f5f7f8;
  background: var(--ctf-sixth);
  border-radius: 15px;
  margin: 10px;
}

.capsual-list .capsual:first-child {
  margin-left: 0px;
}

/*checkbox scss started*/
.selction-box .form-check-input {
  position: absolute;
  margin-top: 8px;
  margin-left: -1.25rem;
}

.selction-box input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
}

.selction-box .form-check {
  position: relative;
  display: block;
  padding-left: 0;
}

.selction-box .checkbox-custom .form-check-input {
  position: absolute;
  margin-top: 8px;
  margin-left: -1.25rem;
}

.selction-box .checkbox-custom input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
}

.selction-box .checkbox-custom .form-check {
  position: relative;
  display: block;
  padding-left: 0;
}

.selction-box .checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  height: 18px;
  border-radius: 3px;
  width: 18px;
  border: 1px solid #888888;
  background-color: transparent;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.selction-box .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.selction-box .container-blk {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  text-transform: capitalize;
  -webkit-user-select: none;
  user-select: none;
}

.selction-box .container-blk:hover input ~ .checkmark {
  background-color: transparent;
}

.selction-box .container-blk input:checked ~ .checkmark {
  background-color: #7b00a3;
  background-color: var(--primary);
}

.selction-box .container-blk input:checked ~ .checkmark:after {
  display: block;
}

.selction-box .container-blk .checkmark:after {
  left: 5px;
  top: 1.5px;
  width: 6px;
  height: 10px;
  border: solid #f5f7f8;
  border: solid var(--ctf-sixth);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.priviledge-checkbox-list-block .priviledge-checkbox-element {
  margin: 7px 0;
  display: inline-block;
  width: 33.33%;
}

/*custom switch box started*/
.switch-wrapper {
  margin: 10px;
  display: inline-block;
  float: left;
  line-height: 1.6rem;
}

.switch-input {
  display: none;
}

.switch-input:checked + .switch-label {
  background-color: #417505;
  background-color: var(--ctf-fifth);
  transition: all 0.3s ease-in 0s;
}

.switch-input:checked + .switch-label:before {
  right: 13px;
  border-color: #ffffff;
  border-color: var(--ctf-white);
}

.switch-label {
  display: inline-block;
  width: 35px;
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #999999;
  background-color: var(--secondary);
  position: relative;
  border-radius: 100px;
  top: 8px;
}

.switch-label:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  background-color: var(--ctf-white);
  border: 1px solid #999999;
  border: 1px solid var(--secondary);
  border-radius: 100px;
  position: absolute;
  top: 50%;
  bottom: 0;
  right: 29px;
  -webkit-transform: translate(10px, -50%);
          transform: translate(10px, -50%);
  cursor: pointer;
}

/* ====================================
   line-switch style
 =======================================*/
/**/
.line-switch .switch-label {
  height: 15px;
  cursor: pointer;
}

.line-switch .switch-label:before {
  top: 50%;
}

.line-switch .switch-input:checked + .switch-label:before {
  background-color: #ffffff;
  background-color: var(--ctf-white);
}

/*profile btn block*/
.action-btn-block .btn {
  margin: 0 15px;
}

.action-btn-block .btn:first-child {
  margin: 0 15px 0 0px;
}

/*profile date  */
.date-picker-wrapper {
  display: flex;
  align-items: center;
}

.date-picker-wrapper .date-title-block {
  margin-right: 15px;
}

.date-picker-wrapper .start-date {
  margin-right: 25px;
  display: flex;
}

.date-picker-wrapper .end-date {
  display: flex;
}

.date-picker-wrapper .date-picker-img {
  padding-left: 10px;
}

.date-picker-wrapper .date-show {
  padding: 10px 30px;
  background-color: #f5f7f8;
  background-color: var(--ctf-sixth);
  border-radius: 15px;
  border-color: transparent;
  font-size: 14px;
  font-size: var(--ctf-h5);
  font-weight: 700;
  outline: transparent;
}

.date-picker-wrapper .react-datepicker__input-container input {
  height: 40px;
  padding: 10px 30px;
  background-color: #f5f7f8;
  background-color: var(--ctf-sixth);
  border-radius: 15px;
  border-color: transparent;
  font-size: 14px;
  font-size: var(--ctf-h5);
  font-weight: 700;
  outline: transparent;
}

/*common table filter css*/
.table-filter-header .filters-main-wrapper .filter-list-wraper {
  display: flex;
  align-items: flex-end;
}

.table-filter-header .filters-main-wrapper .filter-list-wraper .filter-list-element {
  margin: 0 15px;
  display: inline-flex;
}

.table-filter-header .filters-main-wrapper .filter-list-wraper .filter-list-element:first-child {
  margin-left: 0px;
}

.table-filter-header .filters-main-wrapper .filter-list-wraper .filter-list-element:last-child {
  margin-right: 0px;
}

.table-filter-header .filters-main-wrapper .filter-list-wraper .form-control {
  font-size: 14px;
  font-size: var(--ctf-h5);
  height: 47px;
}

.table-filter-header .filters-main-wrapper .filter-list-wraper .search-block .search-input-filter .form-control {
  background-color: #f5f7f8;
  background-color: var(--ctf-sixth);
  outline: none;
  box-shadow: none !important;
  padding-left: 40px;
}

.table-filter-header .filters-main-wrapper .filter-list-wraper .search-block .search-input-filter .form-control:focus {
  border-color: #7b00a3;
  border-color: var(--primary);
}

.table-filter-header .filters-main-wrapper .filter-list-wraper .search-block .search-input-filter .search-icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  left: 10px;
}

.table-filter-header .filters-main-wrapper .filter-list-wraper .select-filter-block .select-filter .select-filter-wrapper {
  position: relative;
}

.table-filter-header .filters-main-wrapper .filter-list-wraper .select-filter-block .select-filter .select-filter-wrapper select {
  padding-left: 40px;
  background-image: linear-gradient(45deg, transparent 50%, #999999 60%), linear-gradient(135deg, #999999 40%, transparent 50%) !important;
  background-image: linear-gradient(45deg, transparent 50%, var(--secondary) 60%), linear-gradient(135deg, var(--secondary) 40%, transparent 50%) !important;
  background-image: linear-gradient(45deg, transparent 50%, #999999 60%), linear-gradient(135deg, #999999 40%, transparent 50%) !important;
  background-image: linear-gradient(45deg, transparent 50%, var(--secondary) 60%), linear-gradient(135deg, var(--secondary) 40%, transparent 50%) !important;
  background-position: calc(100% - 24px) 21px, calc(100% - 20px) 21px, 100% 0;
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: transparent;
  box-shadow: none;
  height: 47px;
  cursor: pointer;
}

.table-filter-header .filters-main-wrapper .filter-list-wraper .select-filter-block .select-filter .select-filter-wrapper select:focus {
  border-color: #7b00a3;
  border-color: var(--primary);
}

.table-filter-header .filters-main-wrapper .filter-list-wraper .select-filter-block .select-filter .select-filter-wrapper .search-filter-img {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  left: 10px;
}

.table-filter-header .filters-main-wrapper .filter-list-wraper .filter-img {
  width: 40px;
  background: #f5f7f8;
  background: var(--ctf-sixth);
  height: 47px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  border-radius: 5px;
  cursor: pointer;
}

.table-filter-header .filters-main-wrapper .filter-list-wraper .filter-img.active svg path {
  fill: #7b00a3;
  fill: var(--primary);
}

.table-filter-header .other-filter {
  display: none;
}

.table-filter-header .other-filter.active {
  display: block;
}

.table-filter-header .other-filter .selction-box .form-check {
  display: inline-flex;
}

.table-filter-header .other-filter .other-filter-list-element {
  margin: 7px 15px;
  display: inline-block;
}

/*table css started*/
.table-main-block {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #dddddd;
  border: 1px solid var(--ctf-fourth);
  margin-bottom: 0px;
}

.table-main-block .selction-box .form-check-input {
  margin-top: 0px;
}

.table-main-block .selction-box .checkmark {
  top: -15px;
  left: 10px;
  height: 18px;
  border-radius: 3px;
  width: 18px;
  border: 1px solid #888888;
  background-color: transparent;
  -webkit-transform: none;
          transform: none;
}

.table-main-block .table-hover tbody tr:hover {
  background-color: #c3c3c3 !important;
}

.table-main-block .table thead {
  background-color: #ffffff;
  background-color: var(--ctf-white);
}

.table-main-block .table thead th {
  vertical-align: middle;
  height: 60px;
  border-color: #eeeeee;
  border-color: var(--ctf-eighth);
  font-size: 14px;
  letter-spacing: -0.05px;
  line-height: 20px;
  color: #3a3b3f;
  color: var(--ctf-ninth);
  font-weight: 400;
  min-width: 120px;
  border-top: 0px;
}

.table-main-block .table thead th.small {
  min-width: 50px;
  max-width: 50px;
  width: 50px;
}

.table-main-block .table thead th.medium {
  min-width: 82px;
}

.table-main-block .table thead th.large {
  min-width: 100px;
}

.table-main-block .table thead th.large-extra {
  min-width: 160px;
}

.table-main-block .table td {
  vertical-align: middle;
  height: 60px;
  border-color: #eeeeee;
  border-color: var(--ctf-eighth);
  font-size: 12px;
  letter-spacing: -0.05px;
  line-height: 20px;
  color: #000000;
  color: var(--ctf-black);
  font-weight: 600;
}

.table-main-block .table td .block-1 {
  width: 35px;
  height: 35px;
  display: inline-flex;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  min-width: 35px;
}

.table-main-block .table td .block-1 img {
  border-radius: 50%;
}

.table-main-block .table td .block-1:before {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border-radius: 50%;
  display: block;
  right: 0px;
  top: 0px;
  content: " ";
}

.table-main-block .table td .block-1.active:before {
  background-color: #417505;
  background-color: var(--ctf-fifth);
}

.table-main-block .table td .block-1.non-active:before {
  background-color: #d0021b;
  background-color: var(--ctf-danger);
}

.table-main-block .table td .block-2 {
  width: calc(100% - 60px);
  height: 100%;
  display: inline-flex;
  align-self: center;
}

.table-main-block .table td .block-3 {
  width: 7px;
  height: 35px;
  display: inline-flex;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  top: 15px;
}

.table-main-block .table td .block-3:before {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border-radius: 50%;
  display: block;
  right: 0px;
  top: 0px;
  content: " ";
}

.table-main-block .table td .block-3.active:before {
  background-color: #417505;
  background-color: var(--ctf-fifth);
}

.table-main-block .table td .block-3.non-active:before {
  background-color: #d0021b;
  background-color: var(--ctf-danger);
}

.table-main-block .table.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f5f7f8;
  background-color: var(--ctf-sixth);
}

.table-main-block .table.table-striped tbody tr:nth-of-type(even) {
  background-color: #ffffff;
  background-color: var(--ctf-white);
}

.table-main-block .table.table-striped tbody tr td .edit-action {
  cursor: pointer;
}

.table-main-block .table.table-striped tbody tr td .edit-action:hover svg g {
  opacity: 1;
}

.table-main-block .table.table-striped tbody tr td .edit-action:hover svg path {
  fill: #7b00a3;
  fill: var(--primary);
}

/*table-pagination css started*/
.pagination-inner-block {
  padding: 15px 30px;
  background-color: #ffffff;
  background-color: var(--ctf-white);
  border-top: 1px solid #dddddd;
  border-top: 1px solid var(--ctf-third);
  display: inline-flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.pagination-inner-block .status-data-block .status-block .status-wrapper {
  display: inline-block;
  margin-left: 15px;
}

.pagination-inner-block .status-data-block .status-block .status-wrapper:first-child {
  margin-left: 0px;
}

.pagination-inner-block .status-data-block .status-block .status-wrapper .dot-wrapper {
  position: relative;
  padding-left: 25px;
}

.pagination-inner-block .status-data-block .status-block .status-wrapper .dot-wrapper:before {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border-radius: 50%;
  display: block;
  top: 50%;
  left: 5px;
  content: " ";
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.pagination-inner-block .status-data-block .status-block .status-wrapper .dot-wrapper.active:before {
  background-color: #417505;
  background-color: var(--ctf-fifth);
}

.pagination-inner-block .status-data-block .status-block .status-wrapper .dot-wrapper.non-active:before {
  background-color: #d0021b;
  background-color: var(--ctf-danger);
}

.pagination-inner-block .pagination-wrapper {
  margin-left: auto;
}

.pagination-inner-block .row-select select {
  min-width: 70px;
  padding-left: 15px;
  background-image: linear-gradient(45deg, transparent 50%, #999999 60%), linear-gradient(135deg, #999999 40%, transparent 50%) !important;
  background-image: linear-gradient(45deg, transparent 50%, var(--secondary) 60%), linear-gradient(135deg, var(--secondary) 40%, transparent 50%) !important;
  background-image: linear-gradient(45deg, transparent 50%, #999999 60%), linear-gradient(135deg, #999999 40%, transparent 50%) !important;
  background-image: linear-gradient(45deg, transparent 50%, var(--secondary) 60%), linear-gradient(135deg, var(--secondary) 40%, transparent 50%) !important;
  background-position: calc(100% - 25px) 13px, calc(100% - 20px) 13px, 100% 0;
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: transparent;
  box-shadow: none;
  border-color: transparent;
  font-size: 12px;
  font-size: var(--body-font-size);
  cursor: pointer;
}

.pagination-inner-block .row-select select:focus {
  border-color: #7b00a3;
  border-color: var(--primary);
}

.pagination-inner-block .row-select .search-filter-img {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  left: 10px;
}

.pagination-inner-block .row-par-element {
  display: inline-flex;
  padding-left: 20px;
  color: #999999;
  color: var(--secondary);
}

.pagination-inner-block .chevron-block {
  display: inline-flex;
}

.pagination-inner-block .chevron-block .chevron-list-element {
  display: inline-flex;
}

.pagination-inner-block .chevron-block .chevron-list-element a svg g {
  opacity: 1;
}

.pagination-inner-block .chevron-block .chevron-list-element a svg g.arrow {
  fill: #999999;
  fill: var(--secondary);
}

.pagination-inner-block .chevron-block .chevron-list-element a:hover svg g.arrow, .pagination-inner-block .chevron-block .chevron-list-element a:focus svg g.arrow, .pagination-inner-block .chevron-block .chevron-list-element a:active svg g.arrow, .pagination-inner-block .chevron-block .chevron-list-element a.active svg g.arrow {
  fill: #000000;
  fill: var(--ctf-black);
}

/*social media link page*/
.social-link-input .invalid-feedback {
  position: absolute;
  bottom: -16px;
  left: 7px;
}

.social-link-input .form-control {
  padding-left: 50px;
}

.social-link-input .social-link-img {
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

/*delete pop up*/
.delete-pop .modal-body-content {
  padding: 15px;
  text-align: center;
}

.delete-pop .modal-body-content .delete-title {
  padding: 20px 0px;
}

.delete-pop .modal-body-content .delete-data {
  padding-bottom: 20px;
}

/*trainer page css started*/
.plan-list-block .plan-list-element {
  display: inline-flex;
  width: 25%;
  padding: 15px;
  border-radius: 5px;
  background-image: url(/static/media/plan-bg.d7f91c6a.svg);
  background-size: 50px;
  background-position: top right;
  background-repeat: no-repeat;
  background-color: #f5f7f8;
  background-color: var(--ctf-sixth);
}

.plan-list-block .plan-list-element .plan-title .plan-img {
  width: 30px;
  display: inline-block;
  vertical-align: top;
}

.plan-list-block .plan-list-element .plan-title .plan-block-2 {
  display: inline-block;
  width: calc(100% - 45px);
}

/*sucess full block*/
.success-block {
  position: fixed;
  top: 85px;
  left: 300px;
  width: calc(100% - 300px);
  padding: 17px;
  background-color: #417505;
  background-color: var(--ctf-fifth);
  z-index: 9;
  border-radius: 0 0 10px 10px;
  transition: 0.3s ease-in-out;
}

.subscription-plan-list-element {
  display: inline-block;
}

.subscription-plan-list-element .custom-btn {
  background-color: #ffffff;
  padding: 6px 25px;
  border-radius: 10px;
  margin-top: 15px;
  float: left;
}

.subscription-plan-list-element .custom-btn input[type="checkbox"] {
  display: none;
  cursor: pointer;
}

.subscription-plan-list-element .custom-btn label {
  color: #999999;
  color: var(--secondary);
  font-weight: normal;
  position: relative;
  padding-left: 35px;
  font-size: 16px;
  margin-bottom: 0;
  width: 100%;
}

.subscription-plan-list-element .custom-btn input[type="radio"]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 4px;
  content: " ";
  display: block;
  background: #7b00a3;
  background: var(--primary);
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.subscription-plan-list-element .label-blk {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.subscription-plan-list-element .custom-btn-radio {
  background-color: #ffffff;
  padding: 6px 25px;
  border-radius: 10px;
  margin-top: 15px;
}

.subscription-plan-list-element .custom-btn-radio input[type="radio"] {
  display: none;
}

.subscription-plan-list-element .custom-btn-radio label {
  color: #999999;
  color: var(--secondary);
  font-weight: normal;
  position: relative;
  padding-left: 35px;
  font-size: 16px;
  margin-bottom: 0;
  width: 100%;
}

.subscription-plan-list-element .custom-btn-radio label:before {
  content: " ";
  display: inline-block;
  position: absolute;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #7b00a3;
  border: 2px solid var(--primary);
  background-color: transparent;
  left: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.subscription-plan-list-element .custom-btn-radio input[type="radio"]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 4px;
  content: " ";
  display: block;
  background: #7b00a3;
  background: var(--primary);
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

/*dashboard scss started*/
.dash-board-import-panel {
  height: calc(100vh - 86px);
}

.dash-board-import-panel .import-data-content {
  padding: 50px 0px;
}

.dash-board-import-panel .import-btn {
  padding-top: 50px;
}

.common-terms-import-panel {
  height: calc(100vh - 295px);
  min-height: 500px;
}

.common-terms-import-panel .import-terms-block {
  margin-bottom: 30px;
}

.common-terms-import-panel .content {
  height: calc(100vh - 350px);
  overflow-y: auto;
  min-height: 450px;
}

/**/
.custom-dropdown-block .sub-title {
  padding: 10px;
}

.custom-dropdown-block .dropdown-item {
  padding: 10px 10px;
  outline: none;
}

.custom-dropdown-block .dropdown-item span {
  margin-right: 10px;
}

.custom-dropdown-block .dropdown-item.active, .custom-dropdown-block .dropdown-item:active {
  color: #7b00a3;
  color: var(--primary);
  text-decoration: none;
  background-color: transparent;
}

/*accessibility table*/
/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.accordion__section .accordion {
  background-color: #f5f7f8;
  background-color: var(--ctf-sixth);
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  position: relative;
  font-weight: 700;
  border-radius: 5px;
}

.accordion__section .accordion .accordion__title {
  font-size: 18px;
  font-size: var(--ctf-h4);
  font-weight: 500;
  padding-right: 25px;
  text-align: left;
}

.accordion__section .accordion .accordian-plus {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  transition: 0.6s ease;
}

.accordion__section .accordion:hover {
  background-color: #7b00a3;
  background-color: var(--primary);
}

.accordion__section .accordion:hover .accordion__title {
  color: #ffffff;
  color: var(--ctf-white);
}

.accordion__section .accordion:hover .accordian-plus svg path {
  fill: #ffffff;
  fill: var(--ctf-white);
}

.accordion__section .accordion.active {
  background-color: #7b00a3;
  background-color: var(--primary);
}

.accordion__section .accordion.active .accordion__title {
  color: #ffffff;
  color: var(--ctf-white);
}

.accordion__section .accordion.active .accordian-plus {
  -webkit-transform: translate(0, -50%) rotate(45deg);
          transform: translate(0, -50%) rotate(45deg);
  transition: 0.6s ease;
}

.accordion__section .accordion.active .accordian-plus svg path {
  fill: #ffffff;
  fill: var(--ctf-white);
}

.accordion__section .accordion__content {
  background-color: #ffffff;
  background-color: var(--ctf-white);
  overflow: hidden;
  transition: max-height 0.6s ease;
  border: 1px solid #dddddd;
  border: 1px solid var(--ctf-third);
  box-shadow: 0 4px 4px 0 rgba(221, 221, 221, 0.1);
}

.accordion__section .accordion__text {
  font-size: 12px;
  font-size: var(--body-font-size);
  padding: 18px;
}

/*dashboard pie chart*/
.chart-panel {
  background-color: #ffffff;
  background-color: var(--ctf-white);
  height: 100%;
}

.chart-panel .pie-char-block {
  width: 80px;
  height: 80px;
  margin: 35px auto;
}

.description-content-element {
  padding: 15px 0px;
}

.description-content-element .color-box {
  display: block;
  width: 15px;
  height: 15px;
  background-color: transparent;
  content: " ";
  display: inline-flex;
  margin-right: 10px;
  vertical-align: text-top;
  border-radius: 5px;
}

.description-content-element .color-text {
  display: inline-flex;
  flex-direction: column;
  width: calc(100% - 35px);
}

.description-content-element .conetnt-paragraph {
  font-size: 12px;
  font-size: var(--body-font-size);
}

/*text-editor css started*/
.text-editor-main-block {
  padding: 15px;
}

.text-editor-main-block .input-block {
  margin: 30px 0;
}

.text-editor-main-block .rdw-editor-wrapper {
  box-sizing: content-box;
}

.text-editor-main-block .rdw-editor-wrapper .notranslate.public-DraftEditor-content {
  border: 1px solid #dddddd;
  border: 1px solid var(--ctf-third);
  padding: 10px;
  min-height: 250px;
}

/*text-editor css ended*/
.line-chart {
  padding: 50px;
  border-radius: 30px;
  margin-bottom: 30px;
}

.line-chart .content-wrapper {
  padding: 15px 0px;
}

.error-page-img {
  width: 60px;
}

.under-construction-img {
  width: 300px;
}

.custom-capsual-main-block .custom-capsual-list-wrapper {
  max-width: 250px;
  min-width: 250px;
}

.custom-capsual-main-block .custom-capsual-list-wrapper .custom-capsual-element {
  display: inline-block;
  padding: 5px 10px;
  background: #7b00a3;
  background: var(--primary);
  margin: 5px;
  border-radius: 15px;
  color: #ffffff;
  color: var(--ctf-white);
}

.rdw-colorpicker-modal,
.rdw-link-modal {
  right: 5px;
  left: auto !important;
}

.custom-disable input:disabled {
  background-color: transparent;
  border-color: transparent;
  font-weight: 600;
}

.custom-disable .lowSize {
  font-size: 12px;
  font-weight: 600;
}

.custom-disable .PhoneInput {
  padding: 13px 0;
}

.custom-disable .PhoneInputInput {
  color: #000000;
  color: var(--ctf-black);
  font-weight: 500;
}

.eye-hide input[type="password"] {
  -webkit-appearance: none;
       appearance: none;
}

/*custom date picker css */
.react-datepicker__day--keyboard-selected {
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
}

.react-datepicker__month-text--keyboard-selected {
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
}

.react-datepicker__quarter-text--keyboard-selected {
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
}

.react-datepicker__year-text--keyboard-selected {
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
}

react-datepicker__day--selected {
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
}

.react-datepicker__day--in-selecting-range {
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
}

.react-datepicker__day--in-range {
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
}

.react-datepicker__month-text--selected {
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
}

.react-datepicker__month-text--in-selecting-range {
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
}

.react-datepicker__month-text--in-range {
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
}

.react-datepicker__quarter-text--selected {
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
}

.react-datepicker__quarter-text--in-selecting-range {
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
}

.react-datepicker__quarter-text--in-range {
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
}

.react-datepicker__year-text--selected {
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
}

.react-datepicker__year-text--in-selecting-range {
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
}

.react-datepicker__year-text--in-range {
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
}

.react-datepicker__current-month {
  color: #7b00a3 !important;
  color: var(--primary) !important;
}

.react-datepicker-time__header {
  color: #7b00a3 !important;
  color: var(--primary) !important;
}

.react-datepicker-year-header {
  color: #7b00a3 !important;
  color: var(--primary) !important;
}

.react-datepicker__day--selected {
  background-color: #7b00a3 !important;
  background-color: var(--primary) !important;
}

.react-datepicker__header {
  background-color: #f5f7f8 !important;
  background-color: var(--ctf-sixth) !important;
  border-bottom: 1px solid #7b00a3 !important;
  border-bottom: 1px solid var(--primary) !important;
}

.react-datepicker {
  font-family: "Montserrat", sans-serif !important;
  font-family: var(--font-family) !important;
  border: 1px solid #7b00a3 !important;
  border: 1px solid var(--primary) !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
  border-top-color: #7b00a3 !important;
  border-top-color: var(--primary) !important;
}

.react-datepicker__year-read-view--down-arrow::before {
  border-top-color: #7b00a3 !important;
  border-top-color: var(--primary) !important;
}

.react-datepicker__month-read-view--down-arrow::before {
  border-top-color: #7b00a3 !important;
  border-top-color: var(--primary) !important;
}

.react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: #7b00a3 !important;
  border-top-color: var(--primary) !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #7b00a3 !important;
  border-bottom-color: var(--primary) !important;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS */
  .custom-invalid-icon.custom-inputs.with-icon .icons {
    display: none !important;
  }
  .custom-invalid-icon .form-control.is-invalid,
  .custom-invalid-icon .was-validated .form-control:invalid {
    background-position: right calc(0.375em + 0.3rem) center;
  }
}

@supports (-ms-ime-align: auto) {
  .custom-inputs .with-icon .custom-invalid-icon .eye-icon {
    display: none;
  }
}

.password-input .invalid-feedback {
  position: absolute;
}

.password-input .form-control.is-invalid,
.password-input .was-validated .form-control:invalid {
  background-image: none;
}

.ReactFlagsSelect-module_selectOptionWithlabel__2GpmM {
  line-height: 20px !important;
}

.summary-block {
  border-radius: 5px;
  border-color: #fff;
  z-index: 10;
}

.summary-block .summary-card {
  background-color: #fff;
}

.summary-block .summary-card h5 {
  font-weight: 700;
}

.summary-block .summary-card div {
  color: #7b00a3;
  font-size: 2.5rem;
  font-weight: 500;
  margin-top: 3rem;
}

.admin-panel .apexcharts-toolbar {
  z-index: 0;
}

.admin-panel .date-picker-wrapper .react-datepicker__input-container input {
  width: 100%;
}

.admin-panel h5.mb-1.f-bold.d-inline-flex label.number-lable {
  display: inline-block;
  width: 25px;
}
/*# sourceMappingURL=style.css.map */
/*up to width 1550px*/
@media screen and (max-width:1550px) {
  :root {
    /*font-size root*/
    --font-family: 'Montserrat', sans-serif;
    --ctf-h1: 32px;
    --ctf-h2: 22px;
    --ctf-h3: 18px;
    --ctf-h4: 16px;
    --ctf-h5: 14px;
  } 
  /* contact us options */
  .option .big-58 {
    font-size: 45px;
    line-height: 60px;
  }
  .plan-list-block .plan-list-element {
    width: 33%;
  }
  .rdw-link-modal {
    top: 35px;
    left: auto!important;
    right: 0px;
  }
  .rdw-colorpicker-modal{
    left: auto!important;
    right: 0px;
  }
  .rdw-editor-toolbar{
    justify-content: center;
  }
}

@media screen and (max-width: 1399px)
{
.rdw-link-modal {
    top: 35px;
    left: 35px!important;
    right: 0px;
}
}
/*up to width 1199px*/
@media screen and (max-width:1199px) {

  .panel {
    padding: 25px 25px;
  }
  /* contact us options */
  .option .big-58 {
    font-size: 35px;
    font-weight: bold;
    line-height: 50px;
  }
  .option .title-block {
    padding-left: 0px;
  }

  .admin-layout-body{
    width:100%;
    height: calc(100vh - 86px);
    margin-left: 0px;
  }
  .sidebar {
    width: 300px;
    left: -310px;
    box-shadow: 0px 6px 5px 5px rgba(221,221,221,1);
  }

  .sidebar .close-icon-sidebar{
    display: inline-block;
  }
  .sidebar.active{
    left: 0px;
  }

  .plan-list-block .plan-list-element {
    width: 50%;
  }
  /*success ful block*/
  .success-block {
    left: 0px;
    width: 100%;   
  }
  .information-block .data-block .block-2 {
    width: 50px;
    height: 50px;
    padding: 10px;
  }
  .information-block .data-block .block-1 {
    width: calc(100% - 50px);
  }
  .navbar-block .navbar {
    padding: 10px 25px;
  }
  .sidebar .sidebar-wrapper .list-element.active .curve ,.sidebar .sidebar-wrapper .list-element.active .curve-2  {
    display: none;
  }
}

/*up to width 991px*/
@media screen and (max-width:991px) {
  :root {
    /*font-size root*/
    --ctf-h1: 24px;
    --ctf-h2: 20px;
    --ctf-h3: 16px;
    --ctf-h4: 14px;
    --ctf-h5: 12px;
  }
  h1,.h1{
      line-height: 35px;
  }
  h2,.h2{
    line-height: 30px;
  }
  h3,.h3{
      line-height: 24px;
  }
  h4,.h4{
      line-height: 20px;
  }

  .panel {
    padding: 25px 15px;
}

  /*contact us page */
  .contactus .btn-block .btn {
    margin: 0 10px;
  }
  .btn-large {
    min-width: 280px;
  }


  /* options */
  .option .contact-title{
    text-align: center!important;
  }
  .option .title-block .text-left{
    text-align: center!important;
  }


  .selction-box .container-blk{
      font-size: 14px;
  }

  /*date-picker */
  /*profile date  */

  .large-pop.modal-dialog {
    max-width: 700px;
  }


  .information-block .card-panel{
    margin-bottom: 15px;    
  }
  .chart-panel{
    margin-bottom: 15px;
    height: calc(100% - 15px);
  }
  .navbar-block .navbar {
    padding: 10px 15px;
  }

  
}
/*up to width 767px*/
@media screen and (max-width:767px) {
  :root {
    /*font-size root*/
    --ctf-h1: 20px;
    --ctf-h2: 18px;
    --ctf-h3: 14px;
    --ctf-h4: 13px;
  }
  /*form css*/
  .custom-inputs {
    margin-bottom: 25px;
  }
  .custom-inputs .form-control {
    padding: 10px;
    height: 42px;
  }
  .custom-inputs .PhoneInputInput{
    padding:10px;
    height: 42px;
    padding-left: 40px;
  }
  .custom-inputs textarea.form-control {
    height: 125px;
  }
  .social-link-input .form-control {
    padding-left: 45px;
  }
  /*contact us page*/
  .contactus .contact-title {
    padding: 40px 0px;
    text-align: center;
  }
  .contactus .btn-block {
    padding: 20px 0px;
  } 
  .contect-body .contact-input-main .wrapper-block {
    padding: 25px 15px;
  }
  .contect-body .contact-input-main .wrapper-block .form-title {
    padding-bottom: 15px;
  }
  .contect-body .contact-input-main .wrapper-block .contect-space {
    padding: 10px 0px;
  }
  .contactus .btn-block .btn {
    margin: 10px 10px;
  }

/*options css */
.option .big-58 {
  font-size: 24px;
  line-height: 34px;
}

.action-btn-block .btn:first-child {
  margin: 15px auto;
}

.priviledge-checkbox-list-block .priviledge-checkbox-element {
    width: 50%;
}

.date-picker-wrapper .react-datepicker__input-container input {
  padding: 10px 15px;
  max-width: 150px;
}

.pagination-inner-block {
  display: block;
  width: 100%;
  text-align: center;
}

.large-pop.modal-dialog {
  max-width: 100%;
}
  .navbar-block .navbar {
    padding: 10px 15px;
  }
  .line-chart {
    padding: 15px;
  }
  .table-filter-header .filters-main-wrapper .filter-list-wraper .filter-list-element {
    margin: 0 10px;
  }
  .under-construction-img{
    width: 150px;
  }
  .common-terms-import-panel {
    height: calc(100vh - 295px);
    min-height: 595px;
}
}
/*up to width 575px*/
@media screen and (max-width:575px) {
 
.action-btn-block {
    text-align: center;
}
.priviledge-checkbox-list-block .priviledge-checkbox-element {
    width: 100%;
}
.pagination-inner-block .row-par-element {
  padding: 5px 10px;
}
.date-picker-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
 }
 .date-picker-wrapper .date-title-block{
   margin-right: 0;
   margin-bottom: 15px;
 }
 .date-picker-wrapper .start-date {
   margin-right: 0px;
   margin-bottom: 15px;
   display: flex;
 }
  .date-picker-wrapper .end-date {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .date-picker-wrapper  .date-picker-img{
     padding-left: 10px;
  }

  .table-filter-header .filters-main-wrapper .filter-list-wraper {
    display: block;
    margin: 0 auto;
    text-align: center;
    align-items: normal;
  }
  .table-filter-header .filters-main-wrapper .filter-list-wraper .filter-list-element {
    margin: 0 0px;
    display: block;
    width: 100%;
  }
  .table-filter-header .filters-main-wrapper .filter-list-wraper .search-block,
  .table-filter-header .filters-main-wrapper .filter-list-wraper .select-filter-block
  {
    text-align: left;
  }
  .table-filter-header .filters-main-wrapper .filter-list-wraper .sort-filter-block{
    display: inline-block;
    width: auto;
    vertical-align: middle;
    margin-right: 15px;
  }
  .table-filter-header .filters-main-wrapper .filter-list-wraper .dropdown.filter-list-element{
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  
  .plan-list-block .plan-list-element {
    width: 100%;
  }
  .adding-popup .card-panel{
    padding: 0;
  }
  .card-panel {
    padding: 12px;
  }
  .card-panel .links {
    padding: 10px 6px;
  }
  .btn-small {
    min-width: 100px;
  }
  .chart-panel{
    height: auto;
  }
  .rdw-colorpicker-modal {
    left: 5px!important;
    right: auto!important;
  }
  .rdw-link-modal  
  {
    left: 5px!important;
    right: auto!important;
  }
  .rdw-image-modal {
    width: 200px!important;
    top: 35px!important;
    left: -21px!important;
  }
  .rdw-embedded-modal ,.rdw-emoji-modal{
    position: absolute;
    width: 180px!important;
    left: -30px !important;
  }
  .rdw-image-modal-btn {
    margin: 0 3px!important;
  }
}
@media screen and (max-width:399px) {
  .rdw-colorpicker-modal {
    right: 5px!important;
    left: auto !important;
  }
  .rdw-link-modal  
  {
    right: 5px !important;
    left: auto !important;
  }
  .rdw-image-modal {
    right: -117px;
  }
  .rdw-embedded-modal, .rdw-emoji-modal {
    
    left: 2px !important;
}
}

@media screen and (max-width:374px) {
  .btn-large {
    min-width: 240px;
  }
}


@media screen and (max-width:349px) {
  .rdw-colorpicker-modal {
   right: 5px!important;
    left: auto!important;
  }
  .rdw-link-modal  
  {
    left: 5px!important;
    right: auto!important;
  }
  .rdw-image-modal {
  
    left: -104px!important;
  }
  .rdw-embedded-modal{
    left: -27px !important;
  }
  .rdw-emoji-modal {
    left: -52px !important;
  }
}


